<template>
  <div class="departmentAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="企业名称:" prop="depaName">
            <el-input type="text" v-model="inform.depaName" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="企业编号:" prop="depaCode">
            <el-input type="text" v-model="inform.depaCode" placeholder="请输入企业编号"></el-input>
          </el-form-item>
          <el-form-item label="父企业:" prop="parentId">
            <el-select v-model="inform.parentId" placeholder="请选择父企业">
              <el-option value="0" label="无"/>
              <el-option v-for="item in departList" :key="item.id" :label="item.depaName" :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="地址:" prop="depaAddress">
            <el-input type="text" readonly v-model="inform.depaAddress" placeholder="请定位企业地址">
              <template slot="append">
                <el-button @click="getLngLat()">定位</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="经度:" prop="depaLng">
            <el-input type="text" v-model="inform.depaLng" placeholder="请输入企业所在经度(高德坐标)" oninput="if(isNaN(value)) { value = null } {value=value.replace(/[^\d.]/g,'')} if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+14)}"></el-input>
          </el-form-item>
          <el-form-item label="纬度:" prop="depaLat">
            <el-input type="text" v-model="inform.depaLat" placeholder="请输入企业所在纬度(高德坐标)" oninput="if(isNaN(value)) { value = null } {value=value.replace(/[^\d.]/g,'')} if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+14)}"></el-input>
          </el-form-item>
          <el-form-item label="描述:" prop="depaDescribe">
            <el-input type="textarea" :rows="5" v-model="inform.depaDescribe" placeholder="请输入描述"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>

    <!--定位组件-->
    <send-loca-map ref="sendLocaRef" @backLocation="backLocation" />
  </div>
</template>
<script>
// import loadBMap from '@/assets/js/loadBMap'
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import { wgs84togcj02,gcj02towgs84 } from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
import sendLocaMap from '@/views/components/send-location'
var vm;
export default {
  data() {
    return {
      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        depaName:'',//企业名称
        depaCode:'',//企业编号
        parentId:'',//父企业Id
        depaAddress:'',//企业地址
        depaLng:'',//经度
        depaLat:'',//纬度
        depaDescribe:'',//描述
      },
      departList :[],//企业数据
      
      cascProps :{
        value:'id',
        label:'depaName',
        children:'childDepartments',
        checkStrictly: true,
        emitPath:false,
      },

      rules :{
        depaName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        // depaCode: [
        //   { required: true, message: '请输入企业编号', trigger: 'blur' },
        //   { min:4, max:32, message: '只能输入4-32位企业编号', trigger: 'blur' },
        // ],
        parentId: [
          { required: true, message: '请选择父企业', trigger: 'change' },
        ],
      },
    }
  },
  components:{
    sendLocaMap
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods:{
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      vm.departmentList()
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '添加企业'
        }else if(type == 'edit'){
          vm.dioTit = '编辑企业'

          let wgs84togcj02Val = wgs84togcj02(item.depaLng,item.depaLat)
          vm.inform.id = item.id
          vm.inform.depaName=item.depaName//企业名称
          vm.inform.depaCode=item.depaCode//企业编号
          vm.inform.parentId=item.parentId//父企业ID
          vm.inform.depaAddress=item.depaAddress//企业地址
          vm.inform.depaLng=wgs84togcj02Val?wgs84togcj02Val[0]:''//企业经度(高德坐标)
          vm.inform.depaLat=wgs84togcj02Val?wgs84togcj02Val[1]:''//企业纬度(高德坐标)
          vm.inform.depaDescribe=item.depaDescribe//备注
        }
      })
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if((vm.inform.depaLng==''&&vm.inform.depaLat!='')||(vm.inform.depaLng!=''&&vm.inform.depaLat=='')){
            vm.$message.warning('经纬度不能只填一个')
            return 
          }
          if(vm.dioType=='add'){
            vm.addDepartmentInsert()
          }else if(vm.dioType=='edit'){
            vm.updateDepartment()
          }
        } else {return false}
      })
    },
    //返回定位数据
    backLocation(val){
      this.inform.depaAddress = val.address
      this.inform.depaLng = val.lng
      this.inform.depaLat = val.lat
    },
    //打开定位弹窗
    getLngLat (){
      if(this.inform.depaAddress!=''){
        let gcj02towgs84Val = gcj02towgs84(this.inform.depaLng,this.inform.depaLat)
        let dataObj = {
          lng:gcj02towgs84Val?gcj02towgs84Val[0]:'',
          lat:gcj02towgs84Val?gcj02towgs84Val[1]:'',
          address:this.inform.depaAddress
        }
        this.$refs.sendLocaRef.init('send',dataObj)
      }else{
        this.$refs.sendLocaRef.init('send')
      }
      
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.isDio = false
    },
    
    //新增
    async addDepartmentInsert (){
      if(vm.inform.depaLng&&vm.inform.depaLat){
        if(vm.inform.depaLng>180||vm.inform.depaLng<-180){
          vm.$message.warning('请输入正确的经度')
          return false
        }
        if(vm.inform.depaLat>90||vm.inform.depaLat<-90){
          vm.$message.warning('请输入正确的纬度')
          return false
        }
        var gcj02towgs84Val = gcj02towgs84(vm.inform.depaLng,vm.inform.depaLat)
      }
      let dataObj = {
        'depaName': vm.inform.depaName,//账号
        'depaCode': vm.inform.depaCode,//密码
        'parentId': vm.inform.parentId,//父企业
        'depaAddress': vm.inform.depaAddress,//企业地址
        'depaLng': gcj02towgs84Val?gcj02towgs84Val[0]:'',//企业经度
        'depaLat': gcj02towgs84Val?gcj02towgs84Val[1]:'',//企业纬度
        'depaDescribe': vm.inform.depaDescribe,//描述
      }
      const res = await apiUrl.addDepartmentInsert(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateDepartment (){
      if(vm.inform.depaLng&&vm.inform.depaLat){
        var gcj02towgs84Val = gcj02towgs84(vm.inform.depaLng,vm.inform.depaLat)
      }
      let dataObj = {
        'id':vm.inform.id,
        'depaName': vm.inform.depaName,//账号
        'depaCode': vm.inform.depaCode,//密码
        'parentId': vm.inform.parentId,//父企业
        'depaAddress': vm.inform.depaAddress,//企业地址
        'depaLng': gcj02towgs84Val?gcj02towgs84Val[0]:'',//企业经度
        'depaLat': gcj02towgs84Val?gcj02towgs84Val[1]:'',//企业纬度
        'depaDescribe': vm.inform.depaDescribe,//描述
      }
      const res = await apiUrl.updateDepartment(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取企业列表
    async departmentList (){
      const res = await apiUrl.departmentList({})
      if(res?.code==200){
        vm.departList = res.data
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.departmentAddEdit{

}
</style>