<template>
  <div class="roleAuth">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDioAuth">
        <div class="btnDiv mb10">
          <el-button type="primary" size="small" @click="checkAll()">一键全选</el-button>
        </div>
        <div class="treeDivAuth">
          <el-tree class="auth-tree" 
            :data="authData" 
            :props="defaultProps" 
            default-expand-all
            node-key="id"
            highlight-current
            ref="treeRef"
            :default-checked-keys="inform.areaIds"
            :expand-on-click-node="false"
            :show-checkbox="true"
            :check-strictly="false"
          ></el-tree>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
        <el-button type="primary" @click="updateAreaAuthorize()" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren2} from '@/utils/utils'
var vm;
export default {
  data() {
    return {
      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        roleName:'',//角色名称
        roleType:'',//角色类型
        roleCode:'',//角色编号
        appIds:[],//应用IDS
        areaIds:[],//区域ids
        roleDescribe:'',//描述
      },

      tabActive :'1',
      authData :[],
      defaultId :[],//默认菜单选中
      
      defaultProps: {
        children: 'childrenList',
        label: 'areaName',
      },

    }
  },
  created(){
    vm = this
  },

  mounted(){
    
  },

  //方法
  methods:{
    //初始化
    async init (item){
      // console.log(item);
      // dioType.value = type
      vm.dioWidth = '60%'
      vm.isDio = true
      await vm.getAreaList()
      vm.$nextTick(() => {
        vm.dioTit = '企业配置'
        vm.inform.id = item.id
        vm.$refs.treeRef.setCheckedKeys([])
        item.areaIds?.forEach(i => {
          // 根据id 拿到 Tree 组件中的node的所有信息
          let node = vm.$refs.treeRef.getNode(i);
          // node.isLeaf：判断当前节点是否为子节点
          if (node.isLeaf) {
            //如果是子节点，就把状态设置成选中
            vm.$refs.treeRef.setChecked(node, true);
          } else {

          }
        })
      })
    },
    //一键全选
    checkAll(){
      this.$refs.treeRef.setCheckedNodes(this.authData)
    },
    //获取树形区域
    async getAreaList (){
      const res = await apiUrl.getAreaList()
      if(res?.code==200){
        this.authData = res.data
        // delChildren2(this.zoneData,'childrenList')
      }else{
        this.$message.error(res.message)
      }
    },
    //修改(企业授权)
    async updateAreaAuthorize (){
      let dataObj = {
        'departmentId':vm.inform.id,
        'areaIds': vm.$refs.treeRef.getCheckedKeys().concat(vm.$refs.treeRef.getHalfCheckedKeys())||[],//选中的id数组
      }
      const res = await apiUrl.updateAreaAuthorize(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio()
      }else{
        vm.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio (){
      vm.inform.id=''
      vm.inform.areaIds=[]
      vm.isDio = false
    }
  }
}
</script>
<style lang='scss'>
.roleAuth{
  .contDioAuth{
    .treeDivAuth{
      padding: 10px;
      height: 530px;
      overflow: auto;
    }
    .transUser{
      text-align: center;
      margin: 50px auto;
    }
  }
}
</style>