<template>
  <div class='system-department mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="100px" inline @submit.native.prevent size="small">
        <el-form-item label="企业名称:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入企业名称" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:system:depa:add')"><i class="el-icon-circle-plus-outline"></i>添加企业</el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:system:depa:del')"><i class="el-icon-delete"></i>批量删除</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <!-- <el-table-column prop="id" label="企业ID" min-width="15%"/> -->
          <el-table-column prop="depaCode" label="企业编号" min-width="15%"/>
          <el-table-column prop="depaName" label="企业名称" min-width="20%"/>
          <el-table-column prop="parDepaName" label="父企业名称" min-width="15%"/>
          <el-table-column prop="depaDescribe" label="描述" min-width="35%"/>
          <el-table-column label="操作" min-width="15%">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('edit',scope.row)" v-if="isAuth('safe:system:depa:edit')"><i class="el-icon-edit"></i>修改</el-button>
              <el-button type="text" class="primary" @click="openDio('config',scope.row)" v-if="isAuth('safe:system:depa:conf')"><i class="el-icon-edit"></i>配置</el-button>
              <el-button  type="text" class="error" @click="openDio('del',scope.row)" v-if="isAuth('safe:system:depa:del')"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <department-add-edit ref="editDio" @addEditSucc="getList()"></department-add-edit>
    <!--配置-->
    <department-config ref="configDio" @addEditSucc="getList()"></department-config>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import departmentAddEdit from './department-add-edit.vue'
import departmentConfig from './department-config.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
var vm;
export default {
  name:'system-department',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],
    }
  },
  components:{
    departmentAddEdit,
    departmentConfig,
    breadCrumb,
  },
  created() {
    vm = this
    vm.initHeight()
  },

  mounted(){
    vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteDepartment(arr)
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增或修改
        }else if(type == 'config'){
          vm.$refs.configDio.init(item);//配置
        }
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteDepartment(arr);//删除
      }).catch(() => {});
    },
    //删除用户
    async deleteDepartment (ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteDepartment(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        'keyword': vm.keyWord.name,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits
      }
      const res = await apiUrl.departmentListPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight () {
      var minWin = window.innerHeight;
      // console.log(minWin-300);
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.system-department{

}
</style>
